import React from "react"

import { SEO, Home } from "../components"

const HomePage: React.FC<{}> = () => {
  return (
    <>
      <SEO title="Home" />
      <Home />
    </>
  )
}

export default HomePage
